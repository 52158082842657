.container {
  --gap: var(--spacing-4);
  --tap-size: 50px;

  box-sizing: border-box;
  display: flex;
  position: fixed;
  bottom: var(--gap);
  left: 50%;
  align-items: flex-end;
  transform: translateX(-50%);
  z-index: var(--z-fixed);
  border-radius: var(--border-radius-1);
  background-color: var(--color-background-25);
  padding: var(--gap);
  padding-bottom: calc(var(--gap) + var(--spacing-3));
  width: auto;
  height: calc(var(--gap) * 2 + var(--spacing-3) + var(--tap-size));
  will-change: contents;
  backdrop-filter: blur(16px) saturate(200%) brightness(100%);
}

@media screen and (width <= 768px) {
  .container {
    max-width: 90vw;
    overflow: auto hidden;
  }
}
