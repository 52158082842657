/* TODO - <150ms rule? */
:root {
  /* timings */
  --timing-instant: 0ms;
  --timing-fastest: 150ms;
  --timing-faster: 250ms;
  --timing-fast: 350ms;
  --timing-intermediate: 450ms;
  --timing-slow: 550ms;
  --timing-slower: 650ms;
  --timing-slowest: 750ms;

  /* easings */
  --ease: cubic-bezier(0.25, 0.1, 0.25, 1);
  --ease-in: cubic-bezier(0.42, 0, 1, 1);
  --ease-out: cubic-bezier(0, 0, 0.58, 1);
  --ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);

  /* cubic-bezier(0.45, 0.02, 0.09, 0.98) */
}

[data-animate='true'] {
  transition: var(--timing-fast) background-color var(--ease-out),
    var(--timing-faster) color var(--ease-out),
    var(--timing-faster) border-color var(--ease-out);
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
    animation: none !important;
  }
}
