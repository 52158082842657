.tooltip {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  opacity: 0;
  z-index: var(--z-front);
  border: var(--border-thin);
  border-radius: var(--border-radius-1);
  background-color: var(--color-background);
  padding: var(--spacing-2) var(--spacing-3);
  line-height: 1;
  white-space: nowrap;
  color: var(--color-text);
  font-size: var(--font-size-0);
  pointer-events: none;
}

@media screen and (width >= 768px) {
  .tooltip {
    display: unset;
  }
}
