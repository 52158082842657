.item {
  padding-right: var(--gap-text);
  width: var(--tap-size);
  height: var(--tap-size);
  will-change: contents;
  aspect-ratio: 1/1;
}

.item:last-child {
  padding-right: 0;
}
