.button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-text-15);
  border-radius: var(--border-radius-1);
  background-color: var(--color-background);
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 100%;
  color: var(--color-text);
}

.button:focus,
.button:hover {
  outline: none;
  border-color: var(--color-brand);
  color: var(--color-brand);
}
