:root {
  /* Width limiters */
  --max-width: 50rem;
  --max-width-wide: 75rem;
  --max-width-read: 64ch;
  --max-width-full: 100%;

  /* Padding & margin gaps */
  --gap-zero: 0;
  --gap-s: 16px;
  --gap-m: 24px;
  --gap-l: 32px;
  --gap-xl: 48px;
  --gap-xxl: 64px;
  --gap-xxxl: 80px;
  --gap-row: 32px;
  --gap-row-xl: 64px;
  --gap-column: 32px;
  --gap-text: 16px;
  --gap-mini: 8px;
  --dock-height: 80px;

  /* Old styles */
  --spacing-0: 0;
  --spacing-1: 1px;
  --spacing-2: 4px;
  --spacing-3: 8px;
  --spacing-4: 10px;
  --spacing-5: 16px;
  --spacing-6: 24px;
  --spacing-7: 32px;
  --spacing-8: 48px;
  --spacing-9: 64px;
  --spacing-10: 80px;
  --spacing-11: 96px;
  --spacing-12: 120px;
  --spacing-13: 160px;
  --spacing-14: 200px;
  --spacing-15: 240px;

  /* borders */
  --border-0: none;
  --border-thin: 1px solid var(--color-text-25);
  --border-2: 2px solid var(--color-text-50);

  /* Border radii */
  --border-radius-0: 4px;
  --border-radius-1: 8px;
  --border-radius-2: 16px;
  --border-radius-3: 24px;

  /* Z-indices */
  --z-fixed: 3;
  --z-front: 2;
  --z-middle: 1;
  --z-back: -1;

  /* Fixed UI element sizes */
  --min-card-height: 236px;
  --fixed-element-width: 4.5rem;

  /* Box shadows */
  --box-shadow-thin: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  --box-shadow-inset: rgb(50 50 93 / 25%) 0 30px 60px -12px inset,
    rgb(0 0 0 / 30%) 0 18px 36px -18px inset;
  --box-shadow: rgb(0 0 0 / 30%) 0 19px 38px, rgb(0 0 0 / 22%) 0 15px 12px;
}

@media screen and (width >= 1024px) {
  :root {
    --gap-row: 48px;
    --gap-row-xl: 80px;
    --gap-column: 48px;
  }
}

@media screen and (width >= 1280px) {
  :root {
    --gap-row: 64px;
    --gap-row-xl: 96px;
    --gap-column: 64px;
  }
}
