:root {
  /* Base HSL color values */
  --light-h: 0deg;
  --light-s: 0%;
  --light-l: 99%;
  --dark-h: 0;
  --dark-s: 0%;
  --dark-l: 15%;

  /* Fixed colors (same regardless of theme setting) */
  --color-light: hsl(var(--light-h) var(--light-s) var(--light-l));
  --color-dark: hsl(var(--dark-h) var(--dark-s) var(--dark-l));
  --color-light-15: hsla(var(--light-h) var(--light-s) var(--light-l) / 15%);
  --color-dark-15: hsla(var(--dark-h) var(--dark-s) var(--dark-l) / 15%);
  --color-light-25: hsla(var(--light-h) var(--light-s) var(--light-l) / 25%);
  --color-dark-25: hsla(var(--dark-h) var(--dark-s) var(--dark-l) / 25%);
  --color-light-50: hsla(var(--light-h) var(--light-s) var(--light-l) / 50%);
  --color-dark-50: hsla(var(--dark-h) var(--dark-s) var(--dark-l) / 50%);

  /* Light theme */
  --color-brand: hsl(22deg 100% 81%);
  --color-text: var(--color-dark);
  --color-text-15: var(--color-dark-15);
  --color-text-25: var(--color-dark-25);
  --color-text-50: var(--color-dark-50);
  --color-background: var(--color-light);
  --color-background-25: var(--color-light-25);
  --color-background-50: var(--color-light-50);
}

:root.dark {
  /* Dark theme */
  --color-text: var(--color-light);
  --color-text-15: var(--color-light-15);
  --color-text-25: var(--color-light-25);
  --color-text-50: var(--color-light-50);
  --color-background: var(--color-dark);
  --color-background-25: var(--color-dark-25);
  --color-background-50: var(--color-dark-50);
}

/*
  TODO: test this
:root,
:root.dark {
  @media (prefers-contrast: more) {
    --color-text: var(--color-light);
    --color-text-15: var(--color-light);
    --color-text-25: var(--color-light);
    --color-text-50: var(--color-light);
    --color-background: var(--color-dark);
    --color-background-25: var(--color-dark);
    --color-background-50: var(--color-dark);
  }
} */
