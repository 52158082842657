.anchor {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-text-15);
  border-radius: var(--border-radius-1);
  background-color: var(--color-background);
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 100%;
  color: var(--color-text);
}

.anchor:hover,
.anchor:focus {
  outline: none;
  border-color: var(--color-brand);
  color: var(--color-brand);
}

.icon {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.dot {
  position: absolute;
  top: calc(100% + var(--spacing-3));
  left: 50%;
  transform: translate(-50%, -50%);
  transition: var(--timing-faster) opacity var(--ease-in-out);
  opacity: 0;
  border-radius: 50%;
  background-color: var(--color-text-25);
  width: 5px;
  height: 5px;
}

.dot[data-active='true'] {
  opacity: 1;
}
