/* stylelint-disable-next-line selector-id-pattern */
#__next {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

main {
  display: flex;
  flex: 1;
  flex-direction: column;
}

html {
  text-rendering: optimizelegibility;
  line-height: var(--line-height-root);
  font-size: var(--font-size-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  background-color: var(--color-background);
  color: var(--color-text);
}

a {
  color: var(--color-text);
}

a:not([class]) {
  transition: var(--timing-faster) border-color var(--ease-out);
  outline: none;
  border-bottom: var(--border-2);
  text-decoration: none;
  color: var(--color-brand);
}

a:not([class]):hover,
a:not([class]):focus {
  border-color: var(--color-text);
}

@media (prefers-reduced-motion: reduce) {
  a:not([class]) {
    transition: none;
  }
}

small {
  color: var(--color-text-50);
  font-size: var(--font-size-0);
  font-weight: var(--font-weight-normal);
}

.underline {
  display: inline;
  transition: var(--timing-fast) background-size var(--ease-in-out);
  outline: none;
  background: linear-gradient(var(--color-text), var(--color-text)) 0 100%;
  background-repeat: no-repeat;
  background-size: 0 2px;
  text-decoration: none;
}

.underline:focus,
.underline:hover {
  background-size: 100% 2px;
}

/* image zoom library customization */
.image-zoom {
  transition: var(--timing-faster) var(--ease-out) !important;
}

.image-zoom-zoomed {
  border-radius: 0 !important;
}

.image-zoom-wrapper::after {
  background-color: var(--color-background) !important;
}

[aria-describedby]:hover + [role='tooltip'],
[aria-describedby]:focus + [role='tooltip'] {
  transition: var(--timing-faster) opacity var(--ease-in-out);
  transition-delay: var(--timing-fast);
  opacity: 1;
}

/* TODO: move, just testing */
#page-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  transition: transform 0.65s cubic-bezier(0.45, 0.02, 0.09, 0.98) 0s;
  overflow: auto;
  scrollbar-gutter: stable;
}

#page-wrapper[data-search-open='true'] {
  transform: translateY(-50vh);

  /* overflow: hidden; */
}
