.dock-item_item__6Vmd2 {
  padding-right: var(--gap-text);
  width: var(--tap-size);
  height: var(--tap-size);
  will-change: contents;
  aspect-ratio: 1/1;
}

.dock-item_item__6Vmd2:last-child {
  padding-right: 0;
}

.dock_container__Q3N1P {
  --gap: var(--spacing-4);
  --tap-size: 50px;

  box-sizing: border-box;
  display: flex;
  position: fixed;
  bottom: var(--gap);
  left: 50%;
  align-items: flex-end;
  transform: translateX(-50%);
  z-index: var(--z-fixed);
  border-radius: var(--border-radius-1);
  background-color: var(--color-background-25);
  padding: var(--gap);
  padding-bottom: calc(var(--gap) + var(--spacing-3));
  width: auto;
  height: calc(var(--gap) * 2 + var(--spacing-3) + var(--tap-size));
  will-change: contents;
  -webkit-backdrop-filter: blur(16px) saturate(200%) brightness(100%);
          backdrop-filter: blur(16px) saturate(200%) brightness(100%);
}

@media screen and (width <= 768px) {
  .dock_container__Q3N1P {
    max-width: 90vw;
    overflow: auto hidden;
  }
}

.circle-link_anchor__zYygR {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-text-15);
  border-radius: var(--border-radius-1);
  background-color: var(--color-background);
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 100%;
  color: var(--color-text);
}

.circle-link_anchor__zYygR:hover,
.circle-link_anchor__zYygR:focus {
  outline: none;
  border-color: var(--color-brand);
  color: var(--color-brand);
}

.circle-link_icon__KqZ3a {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circle-link_dot__G9BtQ {
  position: absolute;
  top: calc(100% + var(--spacing-3));
  left: 50%;
  transform: translate(-50%, -50%);
  transition: var(--timing-faster) opacity var(--ease-in-out);
  opacity: 0;
  border-radius: 50%;
  background-color: var(--color-text-25);
  width: 5px;
  height: 5px;
}

.circle-link_dot__G9BtQ[data-active='true'] {
  opacity: 1;
}

.tooltip_tooltip__uTUIf {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  opacity: 0;
  z-index: var(--z-front);
  border: var(--border-thin);
  border-radius: var(--border-radius-1);
  background-color: var(--color-background);
  padding: var(--spacing-2) var(--spacing-3);
  line-height: 1;
  white-space: nowrap;
  color: var(--color-text);
  font-size: var(--font-size-0);
  pointer-events: none;
}

@media screen and (width >= 768px) {
  .tooltip_tooltip__uTUIf {
    display: unset;
  }
}

.button_button__GfYH0 {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-text-15);
  border-radius: var(--border-radius-1);
  background-color: var(--color-background);
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 100%;
  color: var(--color-text);
}

.button_button__GfYH0:focus,
.button_button__GfYH0:hover {
  outline: none;
  border-color: var(--color-brand);
  color: var(--color-brand);
}

.visually-hidden {
  visibility: hidden;
}

.sr-only {
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.skip-to-content {
  display: inline;
  position: absolute;
  top: var(--gap-text);
  left: var(--gap-text);
  transition: var(--timing-fast) opacity var(--ease-in-out);
  visibility: hidden;
  opacity: 0;
  z-index: var(--z-fixed);
  pointer-events: none;
}

@media screen and (width >= 768px) {
  .skip-to-content {
    visibility: visible;
  }
}

.skip-to-content:focus {
  opacity: 1;
}

/* TODO - <150ms rule? */
:root {
  /* timings */
  --timing-instant: 0ms;
  --timing-fastest: 150ms;
  --timing-faster: 250ms;
  --timing-fast: 350ms;
  --timing-intermediate: 450ms;
  --timing-slow: 550ms;
  --timing-slower: 650ms;
  --timing-slowest: 750ms;

  /* easings */
  --ease: cubic-bezier(0.25, 0.1, 0.25, 1);
  --ease-in: cubic-bezier(0.42, 0, 1, 1);
  --ease-out: cubic-bezier(0, 0, 0.58, 1);
  --ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);

  /* cubic-bezier(0.45, 0.02, 0.09, 0.98) */
}

[data-animate='true'] {
  transition: var(--timing-fast) background-color var(--ease-out),
    var(--timing-faster) color var(--ease-out),
    var(--timing-faster) border-color var(--ease-out);
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
    animation: none !important;
  }
}

:root {
  /* Base HSL color values */
  --light-h: 0deg;
  --light-s: 0%;
  --light-l: 99%;
  --dark-h: 0;
  --dark-s: 0%;
  --dark-l: 15%;

  /* Fixed colors (same regardless of theme setting) */
  --color-light: hsl(var(--light-h) var(--light-s) var(--light-l));
  --color-dark: hsl(var(--dark-h) var(--dark-s) var(--dark-l));
  --color-light-15: hsla(var(--light-h) var(--light-s) var(--light-l) / 15%);
  --color-dark-15: hsla(var(--dark-h) var(--dark-s) var(--dark-l) / 15%);
  --color-light-25: hsla(var(--light-h) var(--light-s) var(--light-l) / 25%);
  --color-dark-25: hsla(var(--dark-h) var(--dark-s) var(--dark-l) / 25%);
  --color-light-50: hsla(var(--light-h) var(--light-s) var(--light-l) / 50%);
  --color-dark-50: hsla(var(--dark-h) var(--dark-s) var(--dark-l) / 50%);

  /* Light theme */
  --color-brand: hsl(22deg 100% 81%);
  --color-text: var(--color-dark);
  --color-text-15: var(--color-dark-15);
  --color-text-25: var(--color-dark-25);
  --color-text-50: var(--color-dark-50);
  --color-background: var(--color-light);
  --color-background-25: var(--color-light-25);
  --color-background-50: var(--color-light-50);
}

:root.dark {
  /* Dark theme */
  --color-text: var(--color-light);
  --color-text-15: var(--color-light-15);
  --color-text-25: var(--color-light-25);
  --color-text-50: var(--color-light-50);
  --color-background: var(--color-dark);
  --color-background-25: var(--color-dark-25);
  --color-background-50: var(--color-dark-50);
}

/*
  TODO: test this
:root,
:root.dark {
  @media (prefers-contrast: more) {
    --color-text: var(--color-light);
    --color-text-15: var(--color-light);
    --color-text-25: var(--color-light);
    --color-text-50: var(--color-light);
    --color-background: var(--color-dark);
    --color-background-25: var(--color-dark);
    --color-background-50: var(--color-dark);
  }
} */

:root {
  --font-heading: var(--font-family-sans);
  --font-body: var(--font-family-sans);
  --font-weight-normal: 400;
  --font-weight-bold: 600;
  --line-height-root: 1.75;
  --line-height-heading: 1.35;
  --letter-spacing: 0.03em;
  --font-size-micro: 12px;
  --font-size-0: 14px;
  --font-size-1: 100%;
  --font-size-2: 19px;
  --font-size-3: 24px;
  --font-size-4: 32px;
  --font-size-5: 48px;
  --font-size-6: 64px;
  --font-size-giant: clamp(3rem, -1.875rem + 7vw, 7rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: var(--spacing-6);
  margin-bottom: var(--spacing-6);
  line-height: var(--line-height-heading);
  letter-spacing: var(--letter-spacing);
  font-family: var(--font-heading);
  font-weight: var(--font-weight-bold);
}

h1 {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-normal);
}

h2 {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-normal);
}

h3 {
  font-size: var(--font-size-3);
}

h4 {
  font-size: var(--font-size-2);
}

h5 {
  font-size: var(--font-size-1);
}

h6 {
  font-size: var(--font-size-1);
}

body {
  font-family: var(--font-body);
}

p {
  line-height: var(--line-height-root);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-normal);
}

:root {
  /* Width limiters */
  --max-width: 50rem;
  --max-width-wide: 75rem;
  --max-width-read: 64ch;
  --max-width-full: 100%;

  /* Padding & margin gaps */
  --gap-zero: 0;
  --gap-s: 16px;
  --gap-m: 24px;
  --gap-l: 32px;
  --gap-xl: 48px;
  --gap-xxl: 64px;
  --gap-xxxl: 80px;
  --gap-row: 32px;
  --gap-row-xl: 64px;
  --gap-column: 32px;
  --gap-text: 16px;
  --gap-mini: 8px;
  --dock-height: 80px;

  /* Old styles */
  --spacing-0: 0;
  --spacing-1: 1px;
  --spacing-2: 4px;
  --spacing-3: 8px;
  --spacing-4: 10px;
  --spacing-5: 16px;
  --spacing-6: 24px;
  --spacing-7: 32px;
  --spacing-8: 48px;
  --spacing-9: 64px;
  --spacing-10: 80px;
  --spacing-11: 96px;
  --spacing-12: 120px;
  --spacing-13: 160px;
  --spacing-14: 200px;
  --spacing-15: 240px;

  /* borders */
  --border-0: none;
  --border-thin: 1px solid var(--color-text-25);
  --border-2: 2px solid var(--color-text-50);

  /* Border radii */
  --border-radius-0: 4px;
  --border-radius-1: 8px;
  --border-radius-2: 16px;
  --border-radius-3: 24px;

  /* Z-indices */
  --z-fixed: 3;
  --z-front: 2;
  --z-middle: 1;
  --z-back: -1;

  /* Fixed UI element sizes */
  --min-card-height: 236px;
  --fixed-element-width: 4.5rem;

  /* Box shadows */
  --box-shadow-thin: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  --box-shadow-inset: rgb(50 50 93 / 25%) 0 30px 60px -12px inset,
    rgb(0 0 0 / 30%) 0 18px 36px -18px inset;
  --box-shadow: rgb(0 0 0 / 30%) 0 19px 38px, rgb(0 0 0 / 22%) 0 15px 12px;
}

@media screen and (width >= 1024px) {
  :root {
    --gap-row: 48px;
    --gap-row-xl: 80px;
    --gap-column: 48px;
  }
}

@media screen and (width >= 1280px) {
  :root {
    --gap-row: 64px;
    --gap-row-xl: 96px;
    --gap-column: 64px;
  }
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

main {
  display: flex;
  flex: 1;
  flex-direction: column;
}

html {
  text-rendering: optimizelegibility;
  line-height: var(--line-height-root);
  font-size: var(--font-size-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  background-color: var(--color-background);
  color: var(--color-text);
}

a {
  color: var(--color-text);
}

a:not([class]) {
  transition: var(--timing-faster) border-color var(--ease-out);
  outline: none;
  border-bottom: var(--border-2);
  text-decoration: none;
  color: var(--color-brand);
}

a:not([class]):hover,
a:not([class]):focus {
  border-color: var(--color-text);
}

@media (prefers-reduced-motion: reduce) {
  a:not([class]) {
    transition: none;
  }
}

small {
  color: var(--color-text-50);
  font-size: var(--font-size-0);
  font-weight: var(--font-weight-normal);
}

.underline {
  display: inline;
  transition: var(--timing-fast) background-size var(--ease-in-out);
  outline: none;
  background: linear-gradient(var(--color-text), var(--color-text)) 0 100%;
  background-repeat: no-repeat;
  background-size: 0 2px;
  text-decoration: none;
}

.underline:focus,
.underline:hover {
  background-size: 100% 2px;
}

/* image zoom library customization */
.image-zoom {
  transition: var(--timing-faster) var(--ease-out) !important;
}

.image-zoom-zoomed {
  border-radius: 0 !important;
}

.image-zoom-wrapper::after {
  background-color: var(--color-background) !important;
}

[aria-describedby]:hover + [role='tooltip'],
[aria-describedby]:focus + [role='tooltip'] {
  transition: var(--timing-faster) opacity var(--ease-in-out);
  transition-delay: var(--timing-fast);
  opacity: 1;
}

/* TODO: move, just testing */
#page-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  transition: transform 0.65s cubic-bezier(0.45, 0.02, 0.09, 0.98) 0s;
  overflow: auto;
  scrollbar-gutter: stable;
}

#page-wrapper[data-search-open='true'] {
  transform: translateY(-50vh);

  /* overflow: hidden; */
}

@font-face {
font-family: '__sansFont_3709ac';
src: url(/_next/static/media/ebd33d3ab0028d2a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__sansFont_Fallback_3709ac';src: local("Arial");ascent-override: 94.63%;descent-override: 23.50%;line-gap-override: 0.00%;size-adjust: 103.66%
}.__className_3709ac {font-family: '__sansFont_3709ac', '__sansFont_Fallback_3709ac', Helvetica, ui-sans-serif;font-weight: 400
}

